.progress {
    width: 100%;
}

.bar {
    width: 100%;
    background-color: #e0e0e0;
    padding: 3px;
    border-radius: 25px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}

.fill {
    display: block;
    height: 8px;
    border-radius: 25px;
    
    transition: width 500ms ease-in-out;
}