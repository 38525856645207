.pokeball {
    background: url(../../../assets/pokeball.png);
    background-size: contain;

    display: block;
    margin: 120px auto 0 auto;
    width: 40px;
    height: 40px;

    animation-duration: 5s;
    animation-iteration-count: infinite;
    
    border-radius: 100%;
    border: solid #37474f 2px;
  }

.pokeball.bounce {
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
  
@keyframes bounce {
    0% {  transform: scale(1, 1) translateY(-200px); }
    10% { transform: scale(1.1, 0.9) translateY(0); }
    30% { transform: scale(0.9, 1.1) translateY(-100px); }
    50% { transform: scale(1.05, 0.95) translateY(0); }
    57% { transform: scale(1, 1) translateY(-7px); }
    64% { transform: scale(1, 1) translateY(0); }
    100% { transform: scale(1, 1) translateY(0); }
}