.home .list .card {
    background-color: (--default);
    width: 100%;
    height: 40rem;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 2.5rem;

    -webkit-transition: background-color 5s ease-out;
    -moz-transition: background-color 5s ease-out;
    -o-transition: background-color 5s ease-out;
    transition: background-color 5s ease-out;
  
    overflow: hidden;
    z-index: 1;
}

.home .list .card:hover {
    cursor: pointer;
    filter: brightness(90%);
}

.home .list .card.flash{
    filter: brightness(2);
}

.home .list .card .id,
.home .list .card .name-en,
.home .list .card .name-jp,
.home .list .card .region,
.home .list .card .height,
.home .list .card .weight {
    color: white;
}

.home .list .card .id.undefined,
.home .list .card .name-en.undefined,
.home .list .card .name-jp.undefined,
.home .list .card .region.undefined,
.home .list .card .height.undefined,
.home .list .card .weight.undefined {
    color: var(--black);
}

.home .list .card .id,
.home .list .card .name-en,
.home .list .card .name-jp {
    font-weight: 900;
}

.home .list .card .region,
.home .list .card .height,
.home .list .card .weight {
    font-weight: bold;
}

.home .list .card .id{
    font-size: 2rem;
    position: absolute;
    top: 10px;
    left: 10px;
}

.home .list .card .name-en {
    font-size: 4rem;
    position: absolute;
    top: 30px;
    left: 10px;
}

.home .list .card .name-jp {
    font-size: 6rem;
    position: absolute;
    bottom: 0;
    left: 1;
    white-space: nowrap;
}

.home .list .card .region,
.home .list .card .height,
.home .list .card .weight {
    position: absolute;
    font-size: 1.2rem;
}

.home .list .card .region {
    top: 100px;
    right: -30px;
    transform: rotate(-0.25turn); 
}

.home .list .card .height,
.home .list .card .weight {
    left: 10px;
}

.home .list .card .height {
    top: 80px;
}

.home .list .card .weight {
    top: 95px;
}

/* sm */
@media (min-width: 640px) {
    .home .list .card .name-jp {
        font-size: 5.6rem;
    }
}
/* md */
@media (min-width: 768px) {
    .home .list .card .name-en {
        font-size: 3.2rem;
    }
    .home .list .card .name-jp {
        font-size: 5rem;
    }
}

/* lg */
@media (min-width: 1024px){
    .home .list .card .name-jp {
        font-size: 5rem;
    }
}

/* xl */
@media (min-width: 1400px){    
    .home .list .card .name-en {
        font-size: 3rem;
    }
    .home .list .card .name-jp {
        font-size: 4.6rem;
    }
}