.modal .content {
    height: 64rem;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.modal .content:-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.modal .content .visual,
.modal .content .information {
    z-index: 15;
}

.modal .content .visual {
    height: 58rem;
    justify-content: center;
    align-items: center;
}

.modal .content .visual p.id {
    font-size: 3rem;
    font-weight: bolder;
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 100;
}


.modal .content .visual img {
    height: 40rem;
    margin: 4rem 0 0 0;
    cursor: pointer;
}

.modal .content .visual p.name-en {
    color: black;
    font-size: 6rem;
    font-weight: bold;
} 

.modal .content .visual p.name-jp {
    color: black;
    font-size: 3rem;
    font-weight: bold;
} 

.modal .content .information {
    background-color: white;
    margin: 2rem;
    height: 58rem;
    padding: 2rem;
    text-align: left;

    border-radius: 1rem;
    box-shadow: 0 3px 10px rgb(0,0,0,0.2);
    z-index: 1;
}

.modal .content .information h2 {
    display: inline-block;
    padding: 0 0 .4rem 0;
    margin: .4rem 0;
}

.modal .content .information .desc p,
.modal .content .information .char .container,
.modal .content .information .stat .container {
    margin: 1rem;
}

/* Description */
.modal .content .information .desc p,
.modal .content .information .desc p a {
    font-size: 1.2rem;
}

.modal .content .information .desc p a svg {
    margin-left: .6rem;
    padding-top: .4rem;
    height: 1.4rem;
    width: 1.4rem;
}

/* Characteristic */
.modal .content .information .char .container {
    position: relative;
}

.modal .content .information .char .container .details {
    display: flex;
    justify-content: space-between;
}

.modal .content .information .char .container .details h3 {
    text-align: center;
}

.modal .content .information .char .container .details img {
    padding: 0;
    height: 6rem;
    margin: .5rem -0.8rem;
    border-radius: 50%; 
}

.modal .content .information .char .container .details p  {
    display: inline-block;
    margin: .5rem;
    width: 8rem;
    height: 8rem;
    line-height: 8rem; 
    text-align: center;
    font-size: 2.5rem;
    white-space: nowrap;
    border-radius: 50%; 
}

.modal .content .information .char .container .ability p {
    font-size: 1.2rem;
}

.modal .content .information .char .container .evolution {
    position: absolute;
    right: 0rem;
    top: 10rem;
}

.modal .content .information .char .container .evolution .wrapper {
    display: flex;
}

.modal .content .information .char .container .evolution .wrapper img {
    background-color: white;
    padding: 1rem;
    height: 10rem;
    margin: .5rem -0.8rem;
    border-radius: 50%; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.modal .content .information .char .container .others {
    margin-top: .6rem;
}

.modal .content .information .char .container .others p {
    font-size: 1.2rem;
}

/* Statistics */
.modal .content .information .stat {
    margin-top: 4rem;
}
.modal .content .information .stat .container div {
    display: flex;
    justify-content: space-between;
}

.modal .content .information .stat .container span {
    font-size: 1rem;
    font-weight: bold;
}

/* lg */
@media (min-width: 1024px){
    .modal .content {
        grid-template-columns: repeat(2, 1fr);
        overflow-y: hidden;
    }

    .modal .content .visual p.name-en {
        color: white;
    } 
    
    .modal .content .visual p.name-jp {
        color: white;
    } 

    .modal .content .information .char .container .details img {
        height: 8rem;
    }
}