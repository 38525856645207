*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  background-color: var(--primary);
  overflow: hidden;
}

.App {
  text-align: center;
}

:root {
  /* Background Colors */
  --black: #47565B;
  --blue: #93C5FD;
  --brown: #B45309;
  --gray:  #71717A;
  --green: #BEF264;
  --pink: #F9A8D4;
  --purple: #D8B4FE;
  --red: #FCA5A5;
  --white: #D4D4D8;
  --yellow: #FDE047;
  --default: white;

  --primary: #292524;
  --secondary: #ECFEFF;

  --accent: #C76958;
  --accent-c5: #c76958c5;
  --accent-75: #c7695875;
}


.bg-default {
  background-color: var(--default);
}

.bg-default p {
  color: black;
}

.bg-black {
  background-color: var(--black);
}
.c-black {
  color: var(--black);
}
.bb-black h2 {
  border-bottom: 0.4rem solid var(--blue);
}

.bg-blue {
  background-color: var(--blue);
}
.c-blue {
  color: var(--blue);
}
.bb-blue h2 {
  border-bottom: 0.4rem solid var(--blue);
}

.bg-brown {
  background-color: var(--brown);
}
.c-brown {
  color: var(--brown);
}
.bb-brown h2 {
  border-bottom: 0.4rem solid var(--brown);
}

.bg-gray {
  background-color: var(--gray);
}
.c-gray {
  color: var(--gray);
}
.bb-gray h2 {
  border-bottom: 0.4rem solid var(--gray);
}

.bg-green {
  background-color: var(--green);
}
.c-green {
  color: var(--green);
}
.bb-green h2 {
  border-bottom: 0.4rem solid var(--green);
}

.bg-pink {
  background-color: var(--pink);
}
.c-pink {
  color: var(--pink);
}
.bb-pink h2 {
  border-bottom: 0.4rem solid var(--pink);
}

.bg-purple {
  background-color: var(--purple);
}
.c-purple {
  color: var(--purple);
}
.bb-purple h2 {
  border-bottom: 0.4rem solid var(--purple);
}

.bg-red {
  background-color: var(--red);
}
.c-red {
  color: var(--red);
}
.bb-red h2 {
  border-bottom: 0.4rem solid var(--red);
}

.bg-white {
  background-color: var(--white);
}
.c-white {
  color: var(--white);
}
.bb-white h2 {
  border-bottom: 0.4rem solid var(--white);
}

.bg-yellow {
  background-color: var(--yellow);
}
.c-yellow {
  color: var(--yellow);
}
.bb-yellow h2 {
  border-bottom: 0.4rem solid var(--yellow);
}

/* Waves */
.waves .bottom {
  height: 19.5rem;
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
}

.waves .wave1 {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 18rem;
}
.waves .wave2 {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 18rem;
    transform: scaleX(-1);
}

.waves .wave3 {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 18rem;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  display: none;
}