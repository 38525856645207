.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;
}

.modal {
    position: relative;
    background-color: #fff;
    height: auto;
    width: 100rem;
    padding: 0;
    overflow: hidden;
    border-radius: 2.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: opacity 1s ease-in-out;
}

.close {
  color: #aaa;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  z-index: 100;
}
