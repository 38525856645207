.home .list {
    padding: 15rem 2rem 7rem;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-items: center;
    gap: 2rem;
}

.home .not-found {
    height: 100vh;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home .not-found h1 {
    margin: 1rem 0;
}

.home .not-found img {
    height: 50%;
    padding: 5px;
    border: 2px solid var(--black);
    filter: grayscale(100%);
}

/* sm */
@media (min-width: 640px) {
    .home .list  {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

/* md */
@media (min-width: 768px) {
    .home .list {
        padding: 7rem 2rem 7rem;
    }

    .home .list  {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

/* lg */
@media (min-width: 1024px){
    .home .list  {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

/* xl */
/* @media (min-width: 1400px){
    .home .list  {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
} */
