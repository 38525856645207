.dropdown {
    width: 14rem;
    margin: 0 .5rem;
    font-size: 1.4rem;
    position: relative;
}

.dropdown .header {
    color: #f0ffff;
    background-color: var(--accent);
    height: 4rem;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

.dropdown .header:hover {
    background-color: var(--accent-75);
}
  
.dropdown .header .arrow {
    margin-left: auto;
    padding: 3px;

    display: inline-block;

    border: solid var(--primary) ;
    border-width: 0 2px 2px 0;

    transform: rotate(45deg);
    transition: transform 0.3s;
}
  
.dropdown .header .up {
    transform: rotate(-135deg);
}
  
.dropdown .options {
    color: black;
    background-color: var(--secondary);
    height: 20rem;
    width: 100%;
    padding: 0;
    text-align: left;

    position: absolute;
    top: 100%;
    left: 0;

    border: 1px solid var(--accent) ;
    border-radius: 5px;

    list-style-type: none;
    overflow: scroll;
    z-index: 1;
}
  
.dropdown .option {
    padding: 10px;
    cursor: pointer;
}
  
.dropdown .option:hover {
    background-color: var(--accent-c5);
    color: var(--secondary);
}

.dropdown .option.selected {
    color: var(--secondary);
    background-color: var(--accent-75);
}